<template>
	<div class="containerq">
		<img class="img" src="@/static/login-bg.jpg" alt="" />
		<div class="content">
			<div class="title">
				<div @click="clicktitle">天宇明灯</div>
			</div>
			<div class="text">的时刻和的授课计划会计恒等式客户看还得几十块和即可获得数据库好几个都是两个健康的返回谁的规划结构好高大上了刚回到公司尽快给大家开始跨境电商高科技但是口感</div>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		clicktitle() {
			console.log(this.data);
			this.$emit('success');
		}
	}
};
</script>

<style lang="less" scoped>
.containerq {
	margin-bottom: 20px;
	width: 420px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.content {
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	.title {
		cursor: pointer;
		display: flex;
		color: #e96432;
		margin-bottom: 5px;
	}
	.text {
		font-size: 12px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2; /* 显示2行 */
		line-clamp: 2; /* 显示2行 */
	}
}
.img {
	width: 70px;
	height: 70px;
	border-radius: 50%;
}
</style>
